import React, { useState, useEffect } from "react";
import { Input, Spin } from "antd";
import { Button, Image, Toast } from "antd-mobile";
import Http from "../../utils/Http";
import "./index.less";
import { useNavigate, useLocation, useHistory } from "react-router-dom";
const cg = require("./assets/icon_realName_success@3x.png");
const RealName = (props) => {
  const [name, setName] = useState("");
  const [idcard, setIdcard] = useState("");
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [spinbox, setSpinbox] = useState(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const verify = params.get("verify");
  //   var MetaInfo = window.getMetaInfo();
  //   var CertifyUrl = "";
  //   window.location.href = CertifyUrl;
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src =
    "https://cn-shanghai-aliyun-cloudauth.oss-cn-shanghai.aliyuncs.com/web_sdk_js/jsvm_all.js";
  document.head.appendChild(script);

  useEffect(() => {
    if (verify == 1) {
      getWebFaceVerify();
    } else {
      getUserInfo();
    }
  }, []);
  const tips = require("./assets/icon_main_shiming_tips@3x.png");
  const getUserInfo = async () => {
    try {
      setSpinbox(true);
      const res = await Http.postForm(
        "/api/v1/wechat_public/userinfo",
        undefined,
        undefined,
        undefined,
        true
      );
      if (res?.status === 10000) {
        setStatus(res.data.user_info.certification_status);
      }
    } catch (e) {
    } finally {
      setSpinbox(false);
    }
  };
  const getWebFaceVerify = async () => {
    try {
      setSpinbox(true);
      const res = await Http.postForm("/api/v1/wechat_public/webFaceVerify", {
        verify_id: localStorage.getItem("CertifyId") || "",
        name: localStorage.getItem("name") || "",
        card_no: localStorage.getItem("idcard") || "",
      });
      if (res?.status === 10000) {
        setStatus(1);
      } else {
        setStatus(0);
        Toast.show(res.message);
      }
    } catch (e) {
    } finally {
      setSpinbox(false);
    }
  };
  const submits = async () => {
    if (!name || !idcard) {
      Toast.show("请输入完整身份信息");
      return;
    }
    setLoading(true);
    try {
      const res = await Http.postForm(
        "/api/v1/wechat_public/webTokenFaceVerify",
        { mata_infor: window.getMetaInfo(), name: name, card_no: idcard }
      );
      if (res?.status === 10000) {
        localStorage.setItem("CertifyId", res.data.CertifyId);
        localStorage.setItem("name", name);
        localStorage.setItem("idcard", idcard);
        window.location.href = res.data.CertifyUrl;
        remove();
      } else {
        Toast.show(res.message);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  const remove = () => {
    setName("");
    setIdcard("");
  };
  return (
    <div>
      {status === 0 ? (
        <div className="box_out">
          <div>
            <div className="header_title"> 实名认证 </div>
            <div
              style={{
                display: "flex",
                fontSize: 14,
                fontWeight: "bold",
              }}
            >
              <span style={{ width: 100, textAlign: "left" }}>姓名</span>
              <div style={{ width: "100%" }}>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="borderless"
                  style={{ border: "none", textAlign: "right" }}
                  placeholder="请输入姓名"
                  maxLength={16}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                fontSize: 14,
                fontWeight: "bold",
                marginTop: 20,
              }}
            >
              <span style={{ width: 100, textAlign: "left" }}>身份证号</span>
              <div style={{ width: "100%" }}>
                <Input
                  value={idcard}
                  onChange={(e) => setIdcard(e.target.value)}
                  style={{ border: "none", textAlign: "right" }}
                  placeholder="请输入身份证号"
                  // maxLength={16}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="bottom_title">
              <Image
                style={{ marginRight: 8 }}
                src={tips}
                width={24}
                height={24}
              />
              <div> 实名认证通过后无法修改，请谨慎填写。</div>
            </div>
            <Button
              className="starts"
              loading={loading}
              onClick={() => {
                submits();
              }}
            >
              开始认证
            </Button>
            <div className="bottom_text">
              根据《互联网用户账号名称管理规定》的要求，申请开通聊天室，上麦发言，发动态需要上传身份证号圈圈开黑将会严格保护你的数据和隐私安全。
            </div>
          </div>
        </div>
      ) : (
        <div className="cg_box">
          <div className="header_title"> 实名认证 </div>
          <img
            src={cg}
            style={{ width: "100px", height: "100px", marginTop: "60px" }}
          ></img>
          <div>您已认证</div>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          display: "fixed",
          top: "40%",
          left: "50%",
          marginLeft: "-20px",
        }}
      >
        {spinbox ? <Spin size="large" /> : null}
      </div>
    </div>
  );
};
export default RealName;
