import React, { useState, useEffect } from "react";
import { Input, message } from "antd";
import { Button, Image } from "antd-mobile";
import { useNavigate, useLocation } from "react-router-dom";
import "./index.css";
import OtpInput from "react-otp-input";
import { useInterval } from "usehooks-ts";
import Http from "../../../utils/Http";
import { setToken } from "../../../store/app";
import { store } from "../../../store";

const MobileLogin = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = React.useState([]);
  const [time, setTime] = useState(60);
  const [isPlaying, setIsPlaying] = React.useState(true);
  const [disabled, setDisabled] = React.useState(true);
  const { state } = useLocation();
  const valueRef = React.useRef(null);

  const checkCode = async (value) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const res = await Http.postForm(
        "/api/v1/wechat_public/loginByMobileCode",
        {
          mobile: state.tel,
          code: value,
        }
      );
      if (res.status === 10000) {
        store.dispatch(setToken(res.data.token));
        const myValue = sessionStorage.getItem("path");
        if (myValue) {
          navigate(myValue);
        } else {
          navigate("/withdraw");
        }
        // navigate("/charge");
      } else {
        message.warning(res.message);
      }
    } catch (e) {
      message.warning(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setValue(e);
  };
  useInterval(
    () => {
      setTime((old) => {
        let newOld = old - 1;

        if (newOld === 0) {
          setIsPlaying(false);
          setDisabled(false);
          return 60;
        } else {
          return newOld;
        }
      });
    },

    isPlaying ? 1000 : null
  );

  useEffect(() => {
    valueRef.current = value;
    if (valueRef.current.length === 6) {
      checkCode(valueRef.current);
    }
  }, [value]);

  return (
    <div className="mobile-page">
      <div className="mobile-tel">
        {" "}
        {`${state.tel.slice(0, 3)} ${state.tel.slice(3, 7)} ${state.tel.slice(
          7,
          11
        )}`}
      </div>
      <div className="code-text">验证码已发送至手机</div>
      <OtpInput
        // ref={valueRef}
        value={value}
        onChange={(e) => {
          handleChange(e);
        }}
        numInputs={6}
        containerStyle={{ justifyContent: "space-between" }}
        inputStyle={{
          width: 48,
          height: 48,
          borderRadius: "8px",
          border: "1px solid #979797",
        }}
        focusStyle={{
          outline: "none",
          border: "1px solid #979797",
          borderRadius: "8px",
        }}
        isInputNum={true}
      />
      <Button
        disabled={disabled}
        style={{
          height: 48,
          borderRadius: 8,
          backgroundColor: disabled ? "#B0B0B0" : "#FF850E",
          marginTop: 22,
        }}
        block
        onClick={() => {
          navigate("/");
        }}
      >
        <div
          style={{
            color: "#FFFFFF",
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          {disabled ? `重新获取(${time}s)` : "重新获取"}
        </div>
      </Button>
      <div className="bottom">
        登录代表您已同意
        <span style={{ fontSize: 13, color: "#F03550" }}>
          《圈圈开黑用户协议》
        </span>
      </div>
    </div>
  );
};

export default MobileLogin;
